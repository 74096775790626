div.library-button {
    display: none;
}

.VideoRecordingClass video-js {
    background-color: #acb2f2;
}

#creatorElement {
    --primary: #17a34a;
    --background: #ffffff;
    --background-dim: #f3f3f3;
    --background-dim-light: #f9f9f9;
    --primary-foreground: #ffffff;
    --foreground: #161616;
    --base-unit: 8px;
}

#surveyElement {
    --primary: #17a34a;
    --background: #ffffff;
    --background-dim: transparent;
    --background-dim-light: #f9f9f9;
    --primary-foreground: #ffffff;
    --foreground: #161616;
    --base-unit: 8px;
}
/* SurveyJS Creator V1 */
/* --primary-color: #7ff07f;
    --secondary-color: #1ab394;
    --primary-text-color: #4a4a4a;
    --secondary-text-color: #a7a7a7;
    --inverted-text-color: #ffffff;
    --primary-hover-color: #6fe06f;
    --selection-border-color: #1ab394;
    --primary-icon-color: #3d4d5d;
    --primary-bg-color: #f8f8f8;
    --secondary-bg-color: #f4f4f4;
    --primary-border-color: #e7eaec;
    --secondary-border-color: #ddd;
    --error-color: #ed5565; */

.chart-wrapper {
    display: flex;
    flex-direction: column;
    height: 410px;
    overflow: hidden;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    border: 1px solid var(--ag-border-color);
    border-radius: 8px;
}

.chart-wrapper-top {
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 0.5rem;
    /* background-color: #f8f8f8; */
}

.myChartWrapperTop {
    background-color: #f8f8f8;
}

.myChartWrapperTop-dark {
    background-color: #1f2937;
}

.chart-wrapper-body {
    border-top: 1px solid var(--ag-border-color);
    height: 100%;
    background-color: #ffffff;
}

.ag-root-wrapper {
    border-radius: 8px;
}

.correctAnswer {
    background-color: green;
    border-radius: 8px;
    padding: 0 6px 1px 6px;
    color: white
  }
  .incorrectAnswer {
    background-color: red;
    border-radius: 8px;
    padding: 0 6px 1px 6px;
    color: white
  }